<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <div class="content-box">
      <el-row class="table-box">
        <el-row type="flex" justify="space-between">
          <el-row></el-row>
          <el-row class="right-btn">
            <el-button @click="handleSync" class="bg-gradient" type="primary">同步标签</el-button>
            <el-button @click="tagVisible = true" class="bg-gradient" type="primary">新建标签组</el-button>
          </el-row>
        </el-row>
        <el-table
            v-loading="loading"
            :data="tableData"
            style="width: 100%"
            :stripe="true"
            border fit>
          <el-table-column label="序号" width="60">
            <template slot-scope="scope">
              {{scope.$index + 1}}
            </template>
          </el-table-column>
          <el-table-column prop="groupName" label="标签组" min-width="70"></el-table-column>
          <el-table-column label="标签" min-width="70">
            <template slot-scope="scope">
              <el-tag v-for="(tag, index) in scope.row.tagList" :key="index" size="mini">
                {{tag.name}}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="创建人" min-width="70"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="handleEdit(scope.row)">
                <el-link type="primary">编辑</el-link>
              </el-button>
              <el-button type="text" @click="handleDel(scope.row.id)">
                <el-link type="danger">删除</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePagNum" @handleCurrentChangeSub="changeCurrPage"/>
      </el-row>
    </div>
    <el-dialog title="创建标签组" :visible.sync="tagVisible" :close-on-click-modal="false" width="550px" @close="handleClose">
      <el-form ref="form" :model="ruleForm" :rules="rules">
        <el-form-item label="标签组名称" prop="groupName">
          <el-input class="width-280" v-model="ruleForm.groupName" placeholder="请输入标签组名称，名称不可修改"></el-input>
        </el-form-item>
        <el-form-item label="标签" prop="tagList">
          <el-tag v-for="tag in ruleForm.tagList" :key="tag.name" closable @close="removeTag(tag)">
            {{ tag.name }}
          </el-tag>
          <el-input
              class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput"
              size="small" @keyup.enter.native="confirmTag" @blur="confirmTag">
          </el-input>
          <el-button v-else class="button-new-tag" size="small" @click="newTag">+ New Tag</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="tagVisible = false">取消</el-button>
        <el-button @click="handleSave" type="primary">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getPage, saveTagGroup, delTagGroup, syncTag } from "@/api/data/wechat/customer/tag";
import { mapState } from 'vuex'
export default {
  name: 'liveCode',
  data() {
    return {
      crumbs: new Map([
        ['数据中台'], ['企业微信'], ['客户管理'], ['标签管理']
      ]),
      tableData: [],
      loading: true,
      total: 0,
      limit: 0,
      page: 1,
      tagVisible: false,
      ruleForm: {
        groupName: '',
        tagList: []
      },
      rules: {
        groupName: [{ required: true, message: '标签群组名不能为空！' }],
        tagList: [{ required: true, message: '标签不能为空！' }]
      },
      inputVisible: false,
      inputValue: '',
      id: ''
    }
  },
  computed: { ...mapState(['hotelInfo']) },
  mounted() {
    this.limit = sessionStorage.getItem('pageSize') * 1
    this.getTagList()
  },
  methods: {
    // 获取标签群组列表
    getTagList() {
      const query = `?page=${ this.page }&limit=${ this.limit }`,
            params = { hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId }
      getPage(query, params).then(({ success, records, total }) => {
        if (!success) return
        this.loading = false
        this.total = total
        this.tableData = records
      })
    },
    // 改变每页数
    changePagNum(num) {
      this.limit = num
      this.getTagList()
    },
    // 改变当前页
    changeCurrPage(num) {
      this.page = num
      this.getTagList()
    },
    // 移除标签
    removeTag(tag) {
      this.ruleForm.tagList.splice(this.ruleForm.tagList.indexOf(tag), 1);
    },
    // 确定插入标签
    confirmTag() {
      const inputValue = this.inputValue;
      if (inputValue) this.ruleForm.tagList.push({ name: inputValue });
      this.inputVisible = false;
      this.inputValue = '';
    },
    // 新增标签
    newTag() {
      this.inputVisible = true;
      this.$nextTick(_ => this.$refs.saveTagInput.$refs.input.focus());
    },
    // 编辑标签
    handleEdit(row) {
      this.tagVisible = true
      this.id = row.id
      this.groupId = row.groupId
      this.ruleForm.groupName = row.groupName
      row.tagList && (this.ruleForm.tagList = row.tagList.map(i => ({ name: i.name, id: i.id })))
    },
    // 保存标签群组
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return
        const params = { ...this.ruleForm, hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId }
        this.id && Object.assign(params, { id: this.id, groupId: this.groupId })
        saveTagGroup(params).then(({ success }) => {
          if (!success) return
          this.tagVisible = false
          this.getTagList()
          this.$message({ message: '保存标签群组成功！', type: 'success' })
        })
      })
    },
    // 关闭对话框
    handleClose() {
      this.id = ''
      this.ruleForm.groupName = ''
      this.ruleForm.tagList.length = 0
    },
    // 删除标签群组
    handleDel(id) {
      this.$confirm('确定移除？', '提示！', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delTagGroup({ id }).then(({ success }) => {
          if (!success) return
          this.getTagList()
          this.$message({ message: '删除成功！', type: 'success' })
        })
      })
    },
    // 同步标签
    handleSync() {
      const params = { hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId }
      syncTag(params).then(({ success }) => {
        if (!success) return
        this.getTagList()
        this.$message({ message: '同步标签成功！', type: 'success' })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-form {
  .el-form-item__label { width: 100px; }
  .el-form-item__error { margin-left: 100px }
}
</style>
