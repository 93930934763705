import request from "@/api/service";

const api = {
   page: '/event/enterpriseWechat/tagGroup/getPage',
   save: '/event/enterpriseWechat/tagGroup/saveTagGroup',
   delete: '/event/enterpriseWechat/tagGroup/removeTagGroup',
   sync: '/event/enterpriseWechat/tagGroup/synchTag'
}

export function getPage(query, params = {}) {
   return request({
      url: api.page + query,
      method: 'POST',
      data: params
   })
}

export function saveTagGroup(params) {
   return request({
      url: api.save,
      method: 'POST',
      data: params
   })
}

export function delTagGroup(params) {
   return request({
      url: api.delete,
      method: 'POST',
      data: params
   })
}

export function syncTag(params) {
   return request({
      url: api.sync,
      method: 'POST',
      data: params
   })
}
